.graphiql-container {
  text-align: left;
  height: calc(100vh - 25px);
  border-top-left-radius: 15px;
  background-color: #282a36 !important; /* Dark background */
  color: #d4d4d4 !important; /* Light text */
  .graphiql-editors {
    background-color: #282a36 !important;
    color: #ffffff !important;
  }
}

.graphiql-editor {
  background-color: #282a36 !important;
  color: #ffffff !important;
}
