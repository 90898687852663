@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles"; // if using DnD

* {
  box-sizing: border-box !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
.App {
  // background-color: #101935;
}
